import { Action, Location } from "history";
import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { Transition, TransitionStatus } from "react-transition-group";
import { toast } from "sonner";
import { BackLink } from "../../../components/BackLink";
import { Button, ButtonWrap, Submit } from "../../../components/CTA";
import { Chevron } from "../../../components/Chevron";
import { Flex } from "../../../components/Flex";
import { H3 } from "../../../components/Heading";
import Loading from "../../../components/Loading";
import { Modal } from "../../../components/Modal";
import { ShareLink } from "../../../components/ShareLink";
import { Text } from "../../../components/Text";
import { ShareIcon } from "../../../components/icons/ShareIcon";
import { MOBILE_BREAKPOINT } from "../../../config";
import {
  ApprovalSettingsInput,
  ApprovalType,
  BillingPlanType,
  Days,
  Listing,
  ListingStatus,
  ListingType,
  ScheduleInput,
  useBrandListingQuery,
  useCreateListingMutation,
  useGetBrandBillingStatusQuery,
  useUpdateListingMutation,
} from "../../../graphql/generated";
import useAnalytics from "../../../hooks/useAnalytics";
import useGqlClient from "../../../hooks/useGqlClient";
import { authSelectors } from "../../../store/auth/selector";
import { styled } from "../../../styles";
import withMargin, { WithMarginProp } from "../../../styles/withMargin";
import { ApprovalSettings } from "./ApprovalSettings";
import { Availability } from "./Availability";
import { ChooseListingType } from "./ChooseListingType";
import { Event } from "./Event";
import { Images } from "./Images";
import { Locations } from "./Locations";
import { Offer } from "./Offer";
import { Preview } from "./Preview";
import { RedeemAnyTime } from "./RedeemAnyTime";
import { RepeatEvent } from "./RepeatEvent";
import { Requirements } from "./Requirements";
import { Review } from "./Review";

const Page = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  margin-top: -${(p) => p.theme.spacing.xxl};
  padding-top: ${(p) => p.theme.spacing.xxl};
  padding-left: ${(p) => p.theme.spacing.l};
  padding-right: ${(p) => p.theme.spacing.l};
  overflow-y: visible;
  overflow-x: hidden;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-top: ${(p) => p.theme.spacing.l};
    padding-bottom: ${(p) => p.theme.spacing.xl};
  }
`;

const ProgressBarWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    position: fixed;
  }
`;

const ProgressBarBg = styled.div`
  background-color: ${(p) => p.theme.color.card.background};
  border-radius: ${(p) => p.theme.misc.borderRadius};
  width: 100%;
  height: 4px;
`;

export const ProgressBarFg = styled.div<{ width: number }>`
  background-color: ${(p) => p.theme.color.primary};
  border-top-right-radius: ${(p) => p.theme.misc.borderRadius};
  border-bottom-right-radius: ${(p) => p.theme.misc.borderRadius};
  width: ${(p) => p.width}%;
  height: 4px;
  transition: width 0.2s ease-in-out;
`;

const Wrap = styled.div`
  width: 100%;
  max-width: 600px;
  padding-top: 0;
  align-self: center;
  text-align: left;
  margin-bottom: 30px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: ${(p) => p.theme.spacing.xl};
  }
`;

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: center;
  text-align: left;
  padding-bottom: 30px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    min-height: 100vh;
  }
`;

const FormContentWrap = styled.div`
  flex: 1;
  width: 100%;
  max-width: 600px;
`;

export const StyledForm = styled.form`
  justify-content: flex-start;
  flex: 1;
`;

export const Content = styled.div`
  padding: ${(p) => p.theme.spacing.l};
  padding-bottom: 0;
`;

export const Footer = styled.div<WithMarginProp>`
  ${withMargin}
`;

export interface MatchParams {
  page: string;
}

export interface ToggleProductWrapper {
  id: number;
  value: number;
}

export const CreateListing = () => {
  const client = useGqlClient();
  const createListing = useCreateListingMutation(client);
  const queryClient = useQueryClient();
  const brand = useSelector(authSelectors.activeBrand);
  const history = useHistory();
  const { track } = useAnalytics();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [listingName, setListingName] = useState<Listing["name"]>("");
  const [listingDetails, setListingDetails] = useState<string>(
    "We are offering 1 complimentary meal for 2 \n\nIn return, we’d like 1 x reel/tiktok and 3 stories all tagging @joli_app"
  );
  const [toggleProduct, setToggleProduct] = useState<
    ToggleProductWrapper | undefined
  >();
  const [startDate, setStartDate] = useState<Listing["startDate"]>(null);
  const [endDate, setEndDate] = useState<Listing["endDate"]>(null);
  const [days, setDays] = useState<Listing["days"]>([]);
  const [categories, setCategories] = useState<Listing["categories"]>([]);
  const [dietaryPreferences, setDietaryPreferences] =
    useState<Listing["dietaryPreferences"]>(undefined);

  const [timeSlot, setTimeSlot] = useState<number>();
  const [schedules, setSchedules] = useState<ScheduleInput[]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const [locations, setLocations] = useState<string[]>([]);
  const [minFollowers, setMinFollowers] =
    useState<Listing["minFollowers"]>(null);
  const [maxFollowers, setMaxFollowers] =
    useState<Listing["maxFollowers"]>(null);
  const [minEngagementRate, setMinEngagementRate] =
    useState<Listing["minEngagementRate"]>(null);
  const [phoneRequired, setPhoneRequired] =
    useState<Listing["requiresPhone"]>(false);
  const [bookingPlatformAutoCreate, setBookingPlatformAutoCreate] =
    useState<Listing["bookingPlatformAutoCreate"]>(false);
  const [emailRequired, setEmailRequired] = useState<boolean>(true);
  const [images, setImages] = useState<string[]>([]);
  const [listingType, setListingType] = useState<ListingType>();
  const [dmnBookingType, setDmnBookingType] = useState("");
  const [numberOfPeople, setNumberOfPeople] = useState(2);
  const [internalBookingNotes, setInternalBookingNotes] = useState("");
  const [approvalSettings, setApprovalSettings] =
    useState<ApprovalSettingsInput>({
      type: ApprovalType.ApprovalTypeManual,
    });
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);

  const { data: brandBillingData } = useGetBrandBillingStatusQuery(client, {
    id: activeBrandId ? activeBrandId : "",
  });

  const [formInteracted, setFormInteracted] = useState(false);

  useEffect(() => {
    if (!brandBillingData) {
      return;
    }

    const currentPlan = brandBillingData.billingPlans.find(
      (bp) => bp.id === brandBillingData.brand!.billingPlanId
    );

    const shouldShowUpgradePrompt =
      currentPlan &&
      currentPlan.planType === BillingPlanType.BillingPlanTypeStarter
        ? true
        : false;

    setShowUpgradePrompt(shouldShowUpgradePrompt);

    if (shouldShowUpgradePrompt) {
      setApprovalSettings({
        type: ApprovalType.ApprovalTypeAuto,
      });
    }
  }, [brandBillingData]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!formInteracted) return;
      event.preventDefault();
      event.returnValue = ""; // Standard message for unsaved changes
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formInteracted]);

  useEffect(() => {
    const unblock = history.block((location: Location, action: Action) => {
      if (formInteracted && action === "PUSH") {
        const confirm = window.confirm(
          "You have unsaved changes, are you sure you want to leave?"
        );
        if (!confirm) {
          return false;
        }
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [formInteracted, history]);

  // 4. Add an event listener to track form interactions
  useEffect(() => {
    const handleFormInteraction = () => setFormInteracted(true);

    const formElement = document.querySelector("form");
    if (formElement) {
      formElement.addEventListener("input", handleFormInteraction);
      return () =>
        formElement.removeEventListener("input", handleFormInteraction);
    }
  }, []);

  return (
    <Page>
      <Prompt
        when={formInteracted}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Wrap>
        <BackLink margin="0" to="/b/listings">
          <Chevron direction="left" /> Listings
        </BackLink>

        <FormWrap>
          <ProgressBarWrap>
            <Flex align="center">
              <ProgressBarBg>
                <ProgressBarFg width={((currentStep + 1) / 7) * 100} />
              </ProgressBarBg>
            </Flex>
          </ProgressBarWrap>
          <FormContentWrap>
            {currentStep === 0 && (
              <Offer
                setCurrentStep={setCurrentStep}
                listingName={listingName}
                setListingName={setListingName}
                listingDetails={listingDetails}
                setListingDetails={setListingDetails}
                toggleProduct={toggleProduct}
                setToggleProduct={setToggleProduct}
                dietaryPreferences={dietaryPreferences}
                setDietaryPreferences={setDietaryPreferences}
              />
            )}
            {currentStep === 1 && (
              <Images
                setCurrentStep={setCurrentStep}
                images={images}
                setImages={setImages}
              />
            )}
            {currentStep === 2 && (
              <Locations
                newListing={true}
                locations={locations}
                setCurrentStep={setCurrentStep}
                setLocations={setLocations}
              />
            )}
            {currentStep === 3 && !listingType && (
              <ChooseListingType
                listingType={listingType}
                setListingType={setListingType}
                setCurrentStep={setCurrentStep}
              />
            )}
            {currentStep === 3 && listingType === ListingType.Ongoing && (
              <Availability
                setCurrentStep={setCurrentStep}
                setStartDate={setStartDate}
                startDate={startDate}
                locations={locations}
                setEndDate={setEndDate}
                endDate={endDate}
                setDays={setDays}
                days={days}
                listingType={listingType}
                setListingType={setListingType}
                setDmnBookingType={setDmnBookingType}
                dmnBookingType={dmnBookingType}
                numberOfPeople={numberOfPeople}
                setNumberOfPeople={setNumberOfPeople}
                internalBookingNotes={internalBookingNotes}
                setInternalBookingNotes={setInternalBookingNotes}
                bookingPlatformAutoCreate={bookingPlatformAutoCreate}
                setBookingPlatformAutoCreate={setBookingPlatformAutoCreate}
              />
            )}
            {currentStep === 3 && listingType === ListingType.Event && (
              <Event
                timeSlot={timeSlot}
                setCurrentStep={setCurrentStep}
                setTimeSlot={setTimeSlot}
                setListingType={setListingType}
              />
            )}
            {currentStep === 3 && listingType === ListingType.Scheduled && (
              <RepeatEvent
                schedules={schedules}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
                setCurrentStep={setCurrentStep}
                setSchedules={setSchedules}
                setListingType={setListingType}
              />
            )}
            {currentStep === 3 && listingType === ListingType.RedeemAnytime && (
              <RedeemAnyTime
                setCurrentStep={setCurrentStep}
                setListingType={setListingType}
              />
            )}
            {currentStep === 4 && (
              <Requirements
                newListing={true}
                setCurrentStep={setCurrentStep}
                setMinFollowers={setMinFollowers}
                minFollowers={minFollowers}
                setMaxFollowers={setMaxFollowers}
                maxFollowers={maxFollowers}
                minEngagementRate={minEngagementRate}
                setMinEngagementRate={setMinEngagementRate}
                setPhoneRequired={setPhoneRequired}
                phoneRequired={phoneRequired}
                setEmailRequired={setEmailRequired}
                emailRequired={emailRequired}
                categories={categories}
                setCategories={setCategories}
              />
            )}
            {currentStep === 5 && (
              <ApprovalSettings
                showUpgradePrompt={showUpgradePrompt}
                approvalSettings={approvalSettings}
                minFollowers={minFollowers}
                setCurrentStep={setCurrentStep}
                setApprovalSettings={setApprovalSettings}
              />
            )}
            {currentStep === 6 && (
              <Review
                dmnBookingType={dmnBookingType}
                approvalSettings={approvalSettings}
                onSave={(status, redirectUrl) => {
                  createListing.mutate(
                    {
                      brandId: brand ? brand.id : "",
                      name: listingName,
                      details: listingDetails,
                      minFollowers,
                      maxFollowers,
                      schedules:
                        listingType === ListingType.Scheduled
                          ? schedules
                          : undefined,
                      timeSlots:
                        listingType === ListingType.Event
                          ? [timeSlot!]
                          : undefined,
                      requiresPhone: phoneRequired,
                      startDate,
                      endDate,
                      locations,
                      status,
                      days,
                      images,
                      toggleProductId: toggleProduct
                        ? toggleProduct.id
                        : undefined,
                      toggleProductValue: toggleProduct
                        ? toggleProduct.value
                        : undefined,
                      type: listingType ? listingType : ListingType.Ongoing,
                      approvalSettings,
                      dmnBookingTypeID: dmnBookingType,
                      bookingPlatformAutoCreate,
                      categories,
                      minEngagementRate,
                      dietaryPreferences,
                      internalBookingNotes,
                      numberOfPeople,
                    },
                    {
                      onSuccess: (res) => {
                        toast.success("Listing created");
                        track("Create listing", {
                          id: res.createListing.listing?.id
                            ? res.createListing.listing.id
                            : undefined,
                          brand: brand ? brand.name : undefined,
                          listingType: listingType
                            ? listingType
                            : ListingType.Ongoing,
                          minFollowers: minFollowers,
                        });

                        // Reset hasUnsavedChanges after a successful save
                        setFormInteracted(false);

                        // Use a callback to ensure state update is processed before navigation
                        setTimeout(() => {
                          queryClient.resetQueries(
                            ["BrandListings", "BrandBilling", "BrandListing"],
                            {
                              exact: false,
                            }
                          );

                          history.push(
                            redirectUrl
                              ? redirectUrl
                              : status === ListingStatus.StatusActive
                              ? "/b/listings?r=activeListing"
                              : "/b/listings"
                          );
                        }, 0);
                      },
                      onError: () => {
                        alert("Something went wrong");
                      },
                    }
                  );
                }}
                isSaving={createListing.isLoading}
                setCurrentStep={setCurrentStep}
                minFollowers={minFollowers}
                maxFollowers={maxFollowers}
                phoneRequired={phoneRequired}
                emailRequired={true}
                name={listingName}
                details={listingDetails}
                locations={locations}
                type={listingType}
                timeSlot={timeSlot}
                startDate={startDate}
                endDate={endDate}
                schedules={schedules}
                days={days}
                images={images}
                listingStatus={ListingStatus.StatusDraft}
                setShowPreview={setShowPreview}
                showPreview={showPreview}
                categories={categories}
                minEngagementRate={minEngagementRate}
              />
            )}
          </FormContentWrap>
        </FormWrap>

        <Transition
          in={showPreview}
          mountOnEnter={true}
          timeout={500}
          unmountOnExit
        >
          {(state) => (
            <SlideOverContent state={state} isActive={showPreview}>
              <Preview
                images={images}
                listingDetails={listingDetails}
                listingTitle={listingName}
                isActive={showPreview}
                setShowPreview={setShowPreview}
                locations={locations}
                type={listingType}
                timeSlot={timeSlot}
                startDate={startDate}
                endDate={endDate}
                days={days}
                schedules={schedules}
              />
            </SlideOverContent>
          )}
        </Transition>
      </Wrap>
    </Page>
  );
};

export const UpdateListing = () => {
  const client = useGqlClient();
  let { id } = useParams<{ id: string }>();
  const listingQuery = useBrandListingQuery(client, { id });
  const updateListing = useUpdateListingMutation(client);
  const { track } = useAnalytics();
  const queryClient = useQueryClient();
  const history = useHistory();
  const brand = useSelector(authSelectors.activeBrand);

  const [currentStep, setCurrentStep] = useState<number>(6);
  const [listingName, setListingName] = useState<Listing["name"]>("");
  const [listingId, setListingId] = useState<Listing["id"]>("");
  const [listingDetails, setListingDetails] = useState<string>("");
  const [toggleProduct, setToggleProduct] = useState<
    ToggleProductWrapper | undefined
  >();
  const [startDate, setStartDate] = useState<Listing["startDate"]>(null);
  const [publishDate, setPublishDate] = useState<Listing["publishDate"]>(null);
  const [endDate, setEndDate] = useState<Listing["endDate"]>(null);
  const [days, setDays] = useState<Listing["days"]>([]);
  const [categories, setCategories] = useState<Listing["categories"]>([]);
  const [timeSlot, setTimeSlot] = useState<number>();
  const [schedules, setSchedules] = useState<ScheduleInput[]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [shareLinkButtonText, setShareLinkButtonText] = useState("Copy link");
  const [locations, setLocations] = useState<string[]>([]);
  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);
  const [minFollowers, setMinFollowers] =
    useState<Listing["minFollowers"]>(null);
  const [maxFollowers, setMaxFollowers] =
    useState<Listing["maxFollowers"]>(null);
  const [minEngagementRate, setMinEngagementRate] =
    useState<Listing["minEngagementRate"]>(null);
  const [phoneRequired, setPhoneRequired] =
    useState<Listing["requiresPhone"]>(false);
  const [dietaryPreferences, setDietaryPreferences] =
    useState<Listing["dietaryPreferences"]>(undefined);
  const [emailRequired, setEmailRequired] = useState<boolean>(true);
  const [images, setImages] = useState<string[]>([]);
  const [listingType, setListingType] = useState<ListingType | undefined>();
  const [listingStatus, setListingStatus] = useState<ListingStatus>(
    ListingStatus.StatusDraft
  );
  const [bookingPlatformAutoCreate, setBookingPlatformAutoCreate] =
    useState<Listing["bookingPlatformAutoCreate"]>(false);
  const [dmnBookingType, setDmnBookingType] = useState("");
  const [numberOfPeople, setNumberOfPeople] = useState(2);
  const [internalBookingNotes, setInternalBookingNotes] = useState("");
  const [approvalSettings, setApprovalSettings] =
    useState<ApprovalSettingsInput>({ type: ApprovalType.ApprovalTypeManual });

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [formInteracted, setFormInteracted] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const initialFormStateRef = useRef<any>(null);

  useEffect(() => {
    if (!showShare) {
      setShareLinkButtonText("Copy link");
    }
  }, [showShare]);

  const checkForUnsavedChanges = () => {
    if (!isDataLoaded) return;

    const currentFormState = {
      listingName,
      listingDetails,
      toggleProduct,
      startDate,
      endDate,
      days,
      categories,
      dietaryPreferences,
      timeSlot,
      schedules,
      locations,
      minFollowers,
      maxFollowers,
      minEngagementRate,
      phoneRequired,
      emailRequired,
      images,
      listingType,
      dmnBookingType,
      bookingPlatformAutoCreate,
      numberOfPeople,
      internalBookingNotes,
      approvalSettings,
    };

    const changedFields: string[] = [];

    Object.keys(currentFormState).forEach((key) => {
      const currentValue =
        currentFormState[key as keyof typeof currentFormState];
      const initialValue = initialFormStateRef.current[key];

      if (JSON.stringify(currentValue) !== JSON.stringify(initialValue)) {
        changedFields.push(key);
        console.log(`Field '${key}' changed:`, {
          from: initialValue,
          to: currentValue,
        });
      }
    });

    const hasChanges = changedFields.length > 0;
    setHasUnsavedChanges(hasChanges);
  };

  useEffect(() => {
    const handleFormInteraction = () => setFormInteracted(true);

    const formElement = document.querySelector("form");
    if (formElement) {
      formElement.addEventListener("input", handleFormInteraction);
      return () =>
        formElement.removeEventListener("input", handleFormInteraction);
    }
  }, []);

  useEffect(() => {
    if (listingQuery.data) {
      const listing = listingQuery.data.listing;

      setListingId(listing.id);
      setListingName(listing.name);
      setListingDetails(listing.details ? listing.details : "");
      setStartDate(listing.startDate);
      setEndDate(listing.endDate);
      setDays(listing.days);
      setCategories(listing.categories);
      setTimeSlot(listing.timeSlot ? listing.timeSlot : undefined);
      setSchedules(listing.schedules);
      setLocations(listing.locations.map((l) => l.id));
      setMinFollowers(listing.minFollowers);
      setMinEngagementRate(listing.minEngagementRate);
      setMaxFollowers(listing.maxFollowers);
      setPhoneRequired(listing.requiresPhone);
      setEmailRequired(true); // TODO - add emailRequired to listing
      setListingType(listing.type);
      setImages(listing.images);
      setListingStatus(listing.status);
      setDmnBookingType(listing.dmnBookingTypeID || "");
      setDietaryPreferences(listing.dietaryPreferences || []);
      setNumberOfPeople(listing.numberOfPeople || 2);
      setInternalBookingNotes(listing.internalBookingNotes || "");
      setPublishDate(listing.publishDate || null);
      setBookingPlatformAutoCreate(listing.bookingPlatformAutoCreate);

      listing.toggleProductId &&
        listing.toggleProductValue &&
        setToggleProduct({
          id: listing.toggleProductId,
          value: listing.toggleProductValue,
        });

      const currentPlan = listingQuery.data.billingPlans.find(
        (bp) => bp.id === listingQuery.data.listing.brand.billingPlanId
      );

      const shouldShowUpgradePrompt =
        currentPlan &&
        currentPlan.planType === BillingPlanType.BillingPlanTypeStarter;

      setShowUpgradePrompt(
        shouldShowUpgradePrompt ? shouldShowUpgradePrompt : false
      );

      const initialApprovalSettings = shouldShowUpgradePrompt
        ? { type: ApprovalType.ApprovalTypeAuto }
        : listing.approvalSettings || { type: ApprovalType.ApprovalTypeManual };

      setApprovalSettings(initialApprovalSettings);

      // Set the initial form state once all data is loaded
      initialFormStateRef.current = {
        listingName: listing.name,
        listingDetails: listing.details || "",
        toggleProduct:
          listing.toggleProductId && listing.toggleProductValue
            ? { id: listing.toggleProductId, value: listing.toggleProductValue }
            : undefined,
        startDate: listing.startDate,
        endDate: listing.endDate,
        days: listing.days,
        categories: listing.categories,
        dietaryPreferences: listing.dietaryPreferences || [],
        timeSlot: listing.timeSlot || undefined,
        schedules: listing.schedules,
        locations: listing.locations.map((l) => l.id),
        minFollowers: listing.minFollowers,
        maxFollowers: listing.maxFollowers,
        minEngagementRate: listing.minEngagementRate,
        phoneRequired: listing.requiresPhone,
        emailRequired: true,
        images: listing.images,
        listingType: listing.type,
        dmnBookingType: listing.dmnBookingTypeID || "",
        numberOfPeople: listing.numberOfPeople || 2,
        internalBookingNotes: listing.internalBookingNotes || "",
        approvalSettings: initialApprovalSettings,
        bookingPlatformAutoCreate: listing.bookingPlatformAutoCreate,
      };

      setIsDataLoaded(true);
    }
  }, [listingQuery.data]);

  useEffect(() => {
    if (isDataLoaded) {
      checkForUnsavedChanges();
    }
  }, [
    listingName,
    listingDetails,
    toggleProduct,
    startDate,
    endDate,
    days,
    categories,
    dietaryPreferences,
    timeSlot,
    schedules,
    locations,
    minFollowers,
    maxFollowers,
    minEngagementRate,
    phoneRequired,
    emailRequired,
    images,
    listingType,
    dmnBookingType,
    bookingPlatformAutoCreate,
    numberOfPeople,
    internalBookingNotes,
    approvalSettings,
    isDataLoaded,
  ]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = ""; // Standard message for unsaved changes
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    const unblock = history.block((location: Location, action: Action) => {
      if (hasUnsavedChanges && action === "PUSH") {
        const confirm = window.confirm(
          "You have unsaved changes, are you sure you want to leave?"
        );
        if (!confirm) {
          return false;
        }
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [hasUnsavedChanges, history]);

  if (listingQuery.isLoading || !listingQuery.data) {
    return (
      <Wrap>
        <Loading />
      </Wrap>
    );
  }

  if (listingQuery.error) {
    return (
      <Wrap>
        <Text>Something went wrong</Text>
      </Wrap>
    );
  }

  return (
    <Page>
      <Prompt
        when={hasUnsavedChanges && formInteracted}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Modal isOpen={showShare} onClose={() => setShowShare(false)}>
        <H3 margin="0 0 0">Share booking link</H3>
        <Text margin="l 0">
          Share this link with your existing network of influencers for them to
          book via Joli
        </Text>
        <ShareLink
          link={`https://web.joliapp.com/listing-invites/${listingId}`}
        />
        <ButtonWrap margin="xl 0 0">
          <Button
            size="s"
            onClick={() => setShowShare(false)}
            margin="0 0 0 0"
            buttonType="secondary"
          >
            Cancel
          </Button>
          <Button
            size="s"
            onClick={() => {
              navigator.clipboard.writeText(
                `https://joliapp.com/invites/${listingId}`
              );
              setShareLinkButtonText("Copied!");
            }}
          >
            {shareLinkButtonText}
          </Button>
        </ButtonWrap>
      </Modal>
      <Wrap>
        <Flex direction="row" justify="space-between">
          <BackLink margin="0" to="/b/listings">
            <Chevron direction="left" /> Listings
          </BackLink>
          <Flex
            direction="row"
            onClick={() => setShowShare(true)}
            align="center"
            style={{ cursor: "pointer" }}
          >
            <Flex
              style={{ marginTop: -3 }}
              align="center"
              justify="center"
              margin="0 s 0 0"
            >
              <ShareIcon colorPreset="secondary" />
            </Flex>
            <Text weight="semi" colorPreset="secondary" margin="0">
              Share
            </Text>
          </Flex>
        </Flex>

        <FormWrap>
          <ProgressBarWrap>
            <Flex align="center">
              <ProgressBarBg>
                <ProgressBarFg width={((currentStep + 1) / 6) * 100} />
              </ProgressBarBg>
            </Flex>
          </ProgressBarWrap>
          <FormContentWrap>
            {currentStep === 0 && (
              <Offer
                setCurrentStep={setCurrentStep}
                listingName={listingName}
                setListingName={setListingName}
                listingDetails={listingDetails}
                setListingDetails={setListingDetails}
                toggleProduct={toggleProduct}
                setToggleProduct={setToggleProduct}
                dietaryPreferences={dietaryPreferences}
                setDietaryPreferences={setDietaryPreferences}
              />
            )}
            {currentStep === 1 ? (
              <Images
                setCurrentStep={setCurrentStep}
                images={images}
                setImages={setImages}
              />
            ) : null}
            {currentStep === 2 && (
              <Locations
                newListing={false}
                setCurrentStep={setCurrentStep}
                setLocations={setLocations}
                locations={locations}
              />
            )}
            {currentStep === 3 && !listingType ? (
              <ChooseListingType
                listingType={listingType}
                setListingType={setListingType}
                setCurrentStep={setCurrentStep}
              />
            ) : null}
            {currentStep === 3 && listingType === ListingType.Ongoing && (
              <Availability
                setCurrentStep={setCurrentStep}
                setStartDate={setStartDate}
                startDate={startDate}
                locations={locations}
                setEndDate={setEndDate}
                endDate={endDate}
                setDays={setDays}
                days={days}
                listingType={listingType}
                setListingType={setListingType}
                setDmnBookingType={setDmnBookingType}
                dmnBookingType={dmnBookingType}
                numberOfPeople={numberOfPeople}
                setNumberOfPeople={setNumberOfPeople}
                internalBookingNotes={internalBookingNotes}
                setInternalBookingNotes={setInternalBookingNotes}
                bookingPlatformAutoCreate={bookingPlatformAutoCreate}
                setBookingPlatformAutoCreate={setBookingPlatformAutoCreate}
              />
            )}
            {currentStep === 3 && listingType === ListingType.Event && (
              <Event
                timeSlot={timeSlot}
                setCurrentStep={setCurrentStep}
                setTimeSlot={setTimeSlot}
                setListingType={setListingType}
              />
            )}
            {currentStep === 3 && listingType === ListingType.Scheduled && (
              <RepeatEvent
                schedules={schedules}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
                setCurrentStep={setCurrentStep}
                setSchedules={setSchedules}
                setListingType={setListingType}
              />
            )}
            {currentStep === 3 && listingType === ListingType.RedeemAnytime ? (
              <RedeemAnyTime
                setCurrentStep={setCurrentStep}
                setListingType={setListingType}
              />
            ) : null}
            {currentStep === 4 && (
              <Requirements
                newListing={false}
                setCurrentStep={setCurrentStep}
                setMinFollowers={setMinFollowers}
                minFollowers={minFollowers}
                setMaxFollowers={setMaxFollowers}
                maxFollowers={maxFollowers}
                setPhoneRequired={setPhoneRequired}
                phoneRequired={phoneRequired}
                setEmailRequired={setEmailRequired}
                emailRequired={emailRequired}
                categories={categories}
                setCategories={setCategories}
                minEngagementRate={minEngagementRate}
                setMinEngagementRate={setMinEngagementRate}
              />
            )}
            {currentStep === 5 && (
              <ApprovalSettings
                showUpgradePrompt={showUpgradePrompt}
                setCurrentStep={setCurrentStep}
                minFollowers={minFollowers}
                setApprovalSettings={setApprovalSettings}
                approvalSettings={approvalSettings}
              />
            )}
            {currentStep === 6 && (
              <Review
                approvalSettings={approvalSettings}
                isSaving={updateListing.isLoading}
                dmnBookingType={dmnBookingType}
                onSave={(status, redirectURL) => {
                  updateListing.mutate(
                    {
                      id,
                      name: listingName,
                      details: listingDetails,
                      minFollowers,
                      maxFollowers,
                      schedules:
                        listingType === ListingType.Scheduled
                          ? schedules
                          : undefined,
                      timeSlots:
                        listingType === ListingType.Event
                          ? [timeSlot!]
                          : undefined,
                      requiresPhone: phoneRequired,
                      startDate,
                      endDate,
                      locations,
                      status,
                      days,
                      images,
                      toggleProductId: toggleProduct
                        ? toggleProduct.id
                        : undefined,
                      toggleProductValue: toggleProduct
                        ? toggleProduct.value
                        : undefined,
                      type: listingType,
                      approvalSettings,
                      dmnBookingTypeID: dmnBookingType,
                      bookingPlatformAutoCreate,
                      categories,
                      dietaryPreferences,
                      minEngagementRate,
                      internalBookingNotes,
                      numberOfPeople,
                    },
                    {
                      onSuccess: (res) => {
                        toast.success("Listing updated");
                        track("Edit listing", {
                          id: res.updateListing.id
                            ? res.updateListing.id
                            : undefined,
                          brand: brand ? brand.name : undefined,
                          listingType: listingType
                            ? listingType
                            : ListingType.Ongoing,
                          minFollowers: minFollowers,
                        });

                        // Reset hasUnsavedChanges after a successful save
                        setHasUnsavedChanges(false);
                        setFormInteracted(false);

                        // Use a callback to ensure state update is processed before navigation
                        setTimeout(() => {
                          queryClient.resetQueries(
                            ["BrandListings", "BrandBilling", "BrandListing"],
                            {
                              exact: false,
                            }
                          );

                          history.push(
                            redirectURL
                              ? redirectURL
                              : !publishDate &&
                                status === ListingStatus.StatusActive
                              ? "/b/listings?r=activeListing"
                              : "/b/listings"
                          );
                        }, 0);
                      },
                      onError: () => {
                        alert("Something went wrong");
                      },
                    }
                  );
                }}
                setCurrentStep={setCurrentStep}
                minFollowers={minFollowers}
                maxFollowers={maxFollowers}
                phoneRequired={phoneRequired}
                emailRequired={emailRequired}
                name={listingName}
                id={listingId}
                details={listingDetails}
                locations={locations}
                type={listingType}
                timeSlot={timeSlot}
                startDate={startDate}
                endDate={endDate}
                schedules={schedules}
                days={days}
                images={images}
                listingStatus={listingStatus}
                setShowPreview={setShowPreview}
                showPreview={showPreview}
                categories={categories}
                minEngagementRate={minEngagementRate}
              />
            )}
          </FormContentWrap>
        </FormWrap>

        <Transition
          in={showPreview}
          mountOnEnter={true}
          timeout={500}
          unmountOnExit
        >
          {(state) => (
            <SlideOverContent state={state} isActive={showPreview}>
              <Preview
                listingDetails={listingDetails}
                listingTitle={listingName}
                isActive={showPreview}
                setShowPreview={setShowPreview}
                locations={locations}
                type={listingType}
                images={images}
                timeSlot={timeSlot}
                startDate={startDate}
                endDate={endDate}
                days={days}
                schedules={schedules}
              />
            </SlideOverContent>
          )}
        </Transition>
      </Wrap>
    </Page>
  );
};

const daysOptions: { label: string; value: string; isChecked: boolean }[] = [];

Object.keys(Days).forEach((d) => {
  daysOptions.push({
    label: d,
    value: d.toUpperCase(),
    isChecked: true,
  });
});

export interface Schedule {
  id: string;
  days: Days[];
  hour: number;
  minute: number;
}

const SlideOverContent = styled.div<{
  isActive: boolean;
  state: TransitionStatus;
}>`
  background-color: ${(p) => p.theme.color.card.background};
  box-shadow: ${(p) => p.theme.shadow.cardHeavy};
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;

  overflow-y: scroll;

  max-width: 360px;
  width: 360px;
  overflow-x: hidden;

  height: 100%;
  transition: 0.2s;
  transform: translateX(
    ${({ state }) => (state === "entering" || state === "entered" ? 0 : 350)}px
  );

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: none;
    width: 100%;
  }
`;

export const SecondaryButton = styled(Submit)`
  background: none;
  padding: calc(${(p) => p.theme.spacing.m} - 2px) ${(p) => p.theme.spacing.m};
  border: 2px solid ${(p) => p.theme.color.button.secondaryBorder};
  color: ${(p) => p.theme.color.typography.text};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

  &:hover {
    background: ${(p) => p.theme.color.typography.secondary}10;
  }
`;
