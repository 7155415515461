import { getUnixTime } from "date-fns";
import posthog from "posthog-js";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { MOBILE_BREAKPOINT } from "../../config";
import {
  BillingPlanType,
  BillingStatus,
  useBrandBillingQuery,
  useBrandNotificationCountQuery,
  useCreateStripePortalMutation,
} from "../../graphql/generated";
import useAnalytics from "../../hooks/useAnalytics";
import useGqlClient from "../../hooks/useGqlClient";
import { useTheme } from "../../hooks/useTheme";
import { useWindowSize } from "../../hooks/useWindowSize";
import RocketEmoji from "../../images/rocket-emoji.png";
import { authSelectors } from "../../store/auth/selector";
import { actions } from "../../store/auth/slice";
import styled, { ThemeProvider, css, keyframes } from "../../styles";
import { darkTheme } from "../../styles/theme";
import withMargin from "../../styles/withMargin";
import withPadding from "../../styles/withPadding";
import { AccountSwitcher } from "../AccountSwitcher";
import { UpgradePrompt } from "../Brand/UpgradePrompt";
import { Button } from "../CTA";
import { Chevron } from "../Chevron";
import { CardDivider } from "../Divider";
import { Flex } from "../Flex";
import { Logo } from "../Logo";
import { LogoMark } from "../LogoMark";
import MobileMenu from "../MobileMenu";
import { Steps } from "../ProgressBar";
import { Text } from "../Text";
import { UpgradePlan } from "../UpgradePlan";
import { View } from "../View";
import { BillingIcon } from "../icons/BillingIcon";
import { BookingsIcon } from "../icons/BookingsIcon";
import { ContentIcon } from "../icons/ContentIcon";
import { IntegrationsIcon } from "../icons/IntegrationsIcon";
import { ListingsIcon } from "../icons/ListingsIcon";
import { LocationsIcon } from "../icons/LocationsIcon";
import { LogoutIcon } from "../icons/LogoutIcon";
import { ProfileIcon } from "../icons/ProfileIcon";
import { ReportsIcon } from "../icons/ReportsIcon";
import { SettingsIcon } from "../icons/SettingsIcon";
import { SupportIcon } from "../icons/SupportIcon";
import { TeamIcon } from "../icons/TeamIcon";
import SetUpGuideModal from "./SetupGuide";

interface MobileNavProps {
  menuToggle: () => void;
  showMenu: boolean;
}

interface BrandNavProps extends MobileNavProps {
  settingsToggle: (s?: boolean) => void;
  showSettings: boolean;
}

const fadeOutIn = keyframes`
  0%, 100% {
    opacity: 1;
  }
  10%, 90% {
    opacity: 0;
  }
`;

const Nav = styled.div<{
  showMenu: boolean;
  activeBanner: boolean;
  colorScheme: "light" | "dark";
}>`
  display: flex;
  height: 100vh;
  z-index: 3;
  user-select: none;
  width: 72px;

  flex-direction: column;
  border-radius: 0px;

  transition: width 200ms ease-in-out;

  .nav-link-label,
  .nav-chevron,
  .account-switcher {
    visibility: hidden;
    opacity: 0;
    transition: opacity 100ms ease-in-out 50ms, visibility 0s linear 0ms;
  }

  .account-switcher-indicator {
    right: 4px;
  }

  .plan-link-card-animation {
    visibility: hidden;
    opacity: 0;
    max-height: 0px;
    transition: max-height 200ms ease-out 0ms, opacity 0ms ease-in-out 0ms,
      visibility 0s ease-in-out 0ms;
  }

  .setup-guide-wrap {
    visibility: hidden;
    opacity: 0;
    max-height: 0px;
    transition: max-height 200ms ease-out 0ms, opacity 0ms ease-in-out 0ms,
      visibility 0s ease-in-out 0ms;
  }

  .setup-guide-link-card {
    transition: padding 150ms ease-in-out 0ms;
    padding: ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.xs};
  }

  .setup-guide-progress-text {
    width: 100%;
    text-align: center;
    transition: width 150ms ease-in-out, white-space 150ms ease-in-out;
  }

  .nav-link-label {
    margin-left: 0;
  }

  .notification,
  .nav-upgrade-prompt {
    @media (min-width: ${MOBILE_BREAKPOINT}px) {
      visibility: hidden;
      opacity: 0;
      transition: opacity 150ms ease-in-out 50ms, visibility 0s linear 0ms;
    }
  }

  .logo-mark,
  .notification-indicator {
    display: block;
    opacity: 1;
    transition: opacity 150ms ease-in-out;
  }

  .word-mark {
    display: none;
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }

  &:hover {
    @media (min-width: ${MOBILE_BREAKPOINT}px) {
      width: 238px;

      .logo-mark,
      .notification-indicator {
        display: none;
        opacity: 0;
      }
      .word-mark {
        display: block;
        opacity: 1;
      }
    }

    .account-switcher-indicator {
      right: 36px;
    }

    .setup-guide-link-card {
      padding: ${(p) => p.theme.spacing.s};
    }

    .setup-guide-progress-text {
      width: 80px;
      white-space: nowrap;
      animation: none;
    }

    .setup-guide-wrap {
      transition: max-height 200ms ease-in-out 100ms,
        opacity 200ms ease-in-out 100ms, visibility 0s linear 0ms;
      visibility: visible;
      max-height: 60px;
      opacity: 1;
      width: 100%;
      overflow: hidden;
    }

    .plan-link-card-animation {
      transition: max-height 200ms ease-in-out 100ms,
        opacity 200ms ease-in-out 100ms, visibility 0s linear 0ms;
      visibility: visible;
      max-height: 60px;
      opacity: 1;
      width: 100%;
      overflow: hidden;
    }

    .nav-link-label {
      margin-left: ${(p) => p.theme.spacing.m};
    }

    .nav-link-label,
    .notification,
    .nav-upgrade-prompt,
    .nav-chevron,
    .account-switcher {
      visibility: visible;
      opacity: 1;
    }

    .notification,
    .nav-upgrade-prompt {
      transition: opacity 150ms ease-in-out 100ms, visibility 0s linear 100ms;
    }
  }

  &:not(:hover) .setup-guide-progress-text {
    animation: ${fadeOutIn} 200ms ease-in-out;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    position: absolute;
    left: 0;
    border-radius: ${(p) => p.theme.misc.borderRadius};
    margin: ${(p) => p.theme.spacing.m};
    height: auto;
    top: ${(p) => (p.activeBanner ? 110 : 60)}px;
    bottom: auto;
    width: 100%;
    max-width: 300px;
    box-sizing: border-box;
    border-right: none;
    transition: display 0s, opacity 150ms ease-in-out;
    box-shadow: ${(p) => p.theme.shadow.cardHeavy};
    background: ${(p) => p.theme.color.card.background};
    ${(p) =>
      p.showMenu
        ? css`
            display: flex;
            opacity: 1;
          `
        : css`
            display: none;
            opacity: 0;
          `}

    .nav-link-label,
    .notification,
    .nav-chevron {
      visibility: visible;
      opacity: 1;
    }

    .nav-link-label {
      margin-left: ${(p) => p.theme.spacing.m};
    }

    .notification {
      transition: opacity 150ms ease-in-out 100ms, visibility 0s linear 100ms;
    }

    .logo-mark,
    .notification-indicator {
      display: none;
      opacity: 0;
    }
    .word-mark {
      display: none;
      opacity: 0;
    }
  }
`;

const BottomSection = styled.div`
  margin-top: auto;
`;

const TrialWrap = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const FooterLinkWrap = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(p) => p.theme.typography.size.s};
  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: ${(p) => p.theme.color.typography.text};
  text-decoration: none;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
  line-height: 130%;
  cursor: pointer;

  span {
    max-height: 20px;
  }

  &:hover,
  &.isActive {
    color: ${(p) => p.theme.color.primary};
    background-color: ${(p) => p.theme.color.input.hover};

    svg {
      path {
        fill: ${(p) => p.theme.color.primary};
      }
    }
  }
`;

const Link = styled(NavLink).attrs({
  activeClassName: "isActive",
})`
  display: flex;
  align-items: center;
  font-size: ${(p) => p.theme.typography.size.s};
  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: ${(p) => p.theme.color.typography.text};
  text-decoration: none;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
  margin: ${(p) => p.theme.spacing.xs} 0;
  line-height: 130%;
  min-height: 23px;

  span {
    margin-top: 1px;
    &.notification {
      color: #fff;
      background: ${(p) => p.theme.color.primary};
    }
  }

  &:hover,
  &.isActive {
    color: ${(p) => p.theme.color.primary};
    background-color: ${(p) => p.theme.color.input.hover};

    .fill-hover {
      path {
        fill: ${(p) => p.theme.color.primary};
      }
    }

    .stroke-hover {
      path {
        stroke: ${(p) => p.theme.color.primary};
      }
    }
  }

  ${withMargin}
`;

const FakeLink = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: ${(p) => p.theme.typography.size.s};
  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: ${(p) => p.theme.color.typography.text};
  text-decoration: none;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
  line-height: 130%;
  min-height: 23px;

  span {
    margin-left: ${(p) => p.theme.spacing.m};
  }

  &:hover,
  &.isActive {
    color: ${(p) => p.theme.color.primary};
    background-color: ${(p) => p.theme.color.input.hover};

    .fill-hover {
      path {
        fill: ${(p) => p.theme.color.primary};
      }
    }

    .stroke-hover {
      path {
        stroke: ${(p) => p.theme.color.primary};
      }
    }
  }

  ${withMargin}
`;

const Dropdown = styled(Text).attrs({
  activeClassName: "isActive",
})`
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: ${(p) => p.theme.typography.size.s};
  font-weight: ${(p) => p.theme.typography.weight.semi};
  color: ${(p) => p.theme.color.typography.text};
  text-decoration: none;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  padding: ${(p) => p.theme.spacing.m} ${(p) => p.theme.spacing.l};
  line-height: 130%;

  span {
    margin-top: 2px;
    &.notification {
      color: ${(p) => p.theme.color.primary};
      background-color: ${(p) => p.theme.color.input.hover};
    }
  }

  &:hover,
  &.isActive {
    color: ${(p) => p.theme.color.primary};
    background-color: ${(p) => p.theme.color.input.hover};

    .fill-hover {
      path {
        fill: ${(p) => p.theme.color.primary};
      }
    }

    .stroke-hover {
      path {
        stroke: ${(p) => p.theme.color.primary};
      }
    }
  }

  ${withMargin}
`;

const IconWrap = styled.div<{ nestedLink?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(p) =>
    p.nestedLink
      ? css`
          margin-left: ${(p) => p.theme.spacing.s};
        `
      : null}
`;

const Notification = styled.span<{ isActive: boolean }>`
  height: ${(p) => p.theme.spacing.m};
  width: ${(p) => p.theme.spacing.m};
  padding: 3px;
  border-radius: 100%;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.theme.typography.size.xxs};
  border-radius: 100%;
  margin-left: auto;
  color: ${(p) => p.theme.color.secondary};
  font-weight: ${(p) => p.theme.typography.weight.bold};
  transition: opacity 150ms ease-in-out;
  opacity: ${(p) => (p.isActive ? 1 : 0)};
  opacity: 0;
`;

const NotificationIndicator = styled.span`
  position: absolute;
  height: ${(p) => p.theme.spacing.xs};
  width: ${(p) => p.theme.spacing.xs};
  border-radius: 100%;
  background-color: ${(p) => p.theme.color.primary};
  right: -${(p) => p.theme.spacing.m};
`;

const StyledCardDivider = styled(CardDivider)`
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const CurrentPlanLinkCard = styled(View)`
  margin: ${(p) => p.theme.spacing.s};
  ${(p) => p.theme.spacing.s} ${(p) => p.theme.spacing.s};
  display: flex;
  flex-direction: column;
  background: ${(p) => p.theme.color.constructive}10 !important;
  gap: 0px;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  border: 1px solid ${(p) => p.theme.color.constructive};
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  ${(p) =>
    p.onClick
      ? css`
          cursor: pointer;
        `
      : ``};

  &.isActive {
    display: none;
  }

  ${withPadding};
  ${withMargin};

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const Wrap = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: ${(p) => p.theme.spacing.l};
  padding-top: ${(p) => p.theme.spacing.l};
  padding-left: ${(p) => p.theme.spacing.l};
  padding-right: ${(p) => p.theme.spacing.l};

  @media (min-width: 1200px) {
    padding-left: ${(p) => p.theme.spacing.xl};
    padding-right: ${(p) => p.theme.spacing.xl};
  }

  grid-template-columns: 1fr 1fr;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const DesktopSwitcher = styled.div`
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

export interface MatchParams {
  page: string;
}

const MobileClickHandler = styled.div<{
  showMenu: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  ${(p) =>
    p.showMenu
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const MobileHeader = (props: MobileNavProps) => {
  return (
    <Wrap>
      <Left>
        <Flex align="center">
          <MobileMenu menuToggle={props.menuToggle} showMenu={props.showMenu} />
          <View margin="0 0 0 s">
            <TrialCard />
          </View>
        </Flex>
      </Left>
      <Right>
        <AccountSwitcher />
      </Right>
    </Wrap>
  );
};

export const BrandNavigation = () => {
  const window = useWindowSize();
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);

  useEffect(() => {
    setShowMenu(false);
  }, [location.pathname]);

  const excludedPaths = [
    "/b/bookings/new/book",
    "/b/bookings/upcoming/book",
    "/b/bookings/in_progress/book",
    "/b/bookings/completed/book",
    "/b/bookings/archived/book",
    "/b/listings/lst",
  ];

  const isExcludedPath = excludedPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  return (
    <>
      {window.width !== undefined &&
      window.width < MOBILE_BREAKPOINT &&
      !isExcludedPath ? (
        <MobileHeader
          showMenu={showMenu}
          menuToggle={() => setShowMenu((s) => !s)}
        />
      ) : null}
      <BrandNavigationMenu
        menuToggle={() => setShowMenu((s) => !s)}
        showMenu={showMenu}
        showSettings={showSettingsDropdown}
        settingsToggle={() => setShowSettingsDropdown((s) => !s)}
      />
    </>
  );
};

export const BrandNavigationMenu = (props: BrandNavProps) => {
  const activeBrandId = useSelector(authSelectors.activeBrandId);
  const client = useGqlClient();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { track } = useAnalytics();
  const [showUpgradePrompt, setShowUpgradePrompt] = useState(false);
  const [showUpgradeOverlay, setShowUpgradeOverlay] = useState(false);
  const account = useSelector(authSelectors.account);

  const { hardShutdown, show: showIntercom } = useIntercom();
  const window = useWindowSize();
  const isDesktop = window.width && window.width > MOBILE_BREAKPOINT;

  const startDate = useMemo(() => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    return date;
  }, []);

  const { data: notificationCountData } = useBrandNotificationCountQuery(
    client,
    {
      filters: {
        startDate: getUnixTime(startDate),
      },
    }
  );

  const { data } = useBrandBillingQuery(
    client,
    {
      brandID: activeBrandId ? activeBrandId : "",
    },
    {
      enabled: true,
    }
  );

  const pendingCount =
    notificationCountData &&
    notificationCountData.bookingsMetrics.totalPendingBookings
      ? notificationCountData.bookingsMetrics.totalPendingBookings
      : 0;
  const rescheduleCount =
    notificationCountData &&
    notificationCountData.bookingsMetrics.totalCreatorRescheduledBookings
      ? notificationCountData.bookingsMetrics.totalCreatorRescheduledBookings
      : 0;

  const notificationCount = pendingCount + rescheduleCount;

  useEffect(() => {
    if (!data || !data.brand) {
      return;
    }

    const currentPlan = data.billingPlans.find(
      (bp) => bp.id === data.brand!.billingPlanId
    );

    const shouldShowUpgradePrompt = !currentPlan
      ? false
      : currentPlan &&
        currentPlan.planType === BillingPlanType.BillingPlanTypeStarter
      ? true
      : false;

    setShowUpgradePrompt(shouldShowUpgradePrompt);
  }, [data]);

  return (
    <ThemeProvider theme={isDesktop ? darkTheme : theme}>
      {showUpgradeOverlay ? (
        <UpgradePlan onCancel={() => setShowUpgradeOverlay(false)} />
      ) : null}
      <Nav
        colorScheme={theme.name as "light" | "dark"}
        activeBanner={
          location && location.pathname !== "/b/billing" ? true : false
        }
        showMenu={props.showMenu}
        onMouseLeave={() => {
          if (props.showSettings) {
            props.settingsToggle(false);
          }
        }}
      >
        <Flex
          justify="center"
          align="flex-start"
          className="word-mark"
          margin="xl l s"
          style={{ marginLeft: 18 }}
        >
          <Logo />
        </Flex>
        <Flex
          justify="center"
          align="center"
          className="logo-mark"
          margin="xl l s"
          style={{ marginLeft: 18 }}
        >
          <LogoMark />
        </Flex>
        <DesktopSwitcher>
          <View padding="s s">
            <AccountSwitcher />
          </View>
        </DesktopSwitcher>

        <Flex direction="column" margin="m s 0">
          {showUpgradePrompt ? (
            <FakeLink
              margin="0 0 s 0"
              onClick={() => setShowUpgradeOverlay(true)}
            >
              <IconWrap>
                <ReportsIcon colorPreset="text" className="stroke-hover" />
              </IconWrap>
              <span className="nav-link-label">Reports</span>
              <div
                style={{ marginLeft: "auto" }}
                className="nav-upgrade-prompt"
              >
                <UpgradePrompt feature="reports" type="icon" />
              </div>
            </FakeLink>
          ) : (
            <Link margin="0 0 xs 0" to="/b/reports">
              <IconWrap>
                <ReportsIcon colorPreset="text" className="stroke-hover" />
              </IconWrap>
              <span className="nav-link-label">Reports</span>
            </Link>
          )}

          {showUpgradePrompt ? (
            <FakeLink
              margin="0 0 xs 0"
              onClick={() => setShowUpgradeOverlay(true)}
            >
              <IconWrap>
                <ContentIcon colorPreset="text" className="stroke-hover" />
              </IconWrap>
              <span className="nav-link-label">Content</span>
              <div
                style={{ marginLeft: "auto" }}
                className="nav-upgrade-prompt"
              >
                <UpgradePrompt feature="content" type="icon" />
              </div>
            </FakeLink>
          ) : (
            <Link margin="0 0 xs 0" to="/b/content">
              <IconWrap>
                <ContentIcon colorPreset="text" className="stroke-hover" />
              </IconWrap>
              <span className="nav-link-label">Content</span>
            </Link>
          )}
          <Link
            isActive={(match, location) => {
              return (
                location.pathname.includes("/b/listings") ||
                location.pathname.includes("/b/create-listing")
              );
            }}
            margin="0 0 xs 0"
            to="/b/listings"
          >
            <IconWrap>
              <ListingsIcon colorPreset="text" className="fill-hover" />
            </IconWrap>
            <span className="nav-link-label">Listings</span>
          </Link>

          <Link margin="0 0 xs 0" to="/b/bookings">
            <Flex align="center" style={{ position: "relative" }}>
              <IconWrap>
                <BookingsIcon colorPreset="text" className="stroke-hover" />
              </IconWrap>
              {notificationCount && notificationCount > 0 ? (
                <NotificationIndicator className="notification-indicator" />
              ) : null}
            </Flex>
            <>
              <span className="nav-link-label">Bookings</span>
            </>
            {notificationCount && notificationCount > 0 ? (
              <>
                <div style={{ marginLeft: "auto" }}>
                  <Notification isActive={true} className="notification">
                    {notificationCount && notificationCount}
                  </Notification>
                </div>
              </>
            ) : null}
          </Link>
          <Link margin="0 0 xs 0" to="/b/locations">
            <IconWrap>
              <LocationsIcon colorPreset="text" className="stroke-hover" />
            </IconWrap>
            <span className="nav-link-label">Locations</span>
          </Link>
          <SettingsDropdown
            stripeCustomerId={
              data && data.brand && data.brand.stripeCustomerId
                ? data.brand.stripeCustomerId
                : undefined
            }
            setShowUpgradeOverlay={setShowUpgradeOverlay}
            showUpgradePrompt={showUpgradePrompt}
            showSettings={props.showSettings}
            settingsToggle={props.settingsToggle}
          />
        </Flex>

        <StyledCardDivider margin="s 0" />

        <BottomSection>
          <DesktopOnly>
            {data &&
            data.brand &&
            (data.brand.billingStatus === BillingStatus.BillingStatusInTrial ||
              data.brand.billingStatus ===
                BillingStatus.BillingStatusFreePlanExpired) ? (
              <TrialCard />
            ) : null}
          </DesktopOnly>
          <Flex direction="column" margin="0 s m">
            <FooterLinkWrap
              onClick={() => {
                showIntercom();
              }}
            >
              <IconWrap>
                <SupportIcon colorPreset="text" />
              </IconWrap>
              <span className="nav-link-label">Support</span>
            </FooterLinkWrap>
            <FooterLinkWrap
              onClick={() => {
                dispatch(actions.logout());
                posthog.reset();
                hardShutdown();
                track("Logout");
              }}
            >
              <IconWrap style={{ marginLeft: 2, marginRight: -2 }}>
                <LogoutIcon colorPreset="text" />
              </IconWrap>
              <span className="nav-link-label">Log out</span>
            </FooterLinkWrap>
          </Flex>
        </BottomSection>
      </Nav>
      <MobileClickHandler
        showMenu={props.showMenu}
        onClick={() => {
          props.menuToggle();
          if (props.showSettings) {
            props.settingsToggle(false);
          }
        }}
      />
    </ThemeProvider>
  );
};

const DesktopOnly = styled.div`
  margin-top: ${(p) => p.theme.spacing.s};
  display: block;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

interface SettingsProps {
  stripeCustomerId?: string;
  showSettings: boolean;
  settingsToggle: (x: boolean) => void;
  showUpgradePrompt: boolean;
  setShowUpgradeOverlay: (x: boolean) => void;
}

const TrialCard = () => {
  const [showUpgrade, setShowUpgrade] = useState(false);
  const theme = useTheme();
  const window = useWindowSize();
  const client = useGqlClient();
  const activeBrandId = useSelector(authSelectors.activeBrandId);

  const { data } = useBrandBillingQuery(
    client,
    {
      brandID: activeBrandId ? activeBrandId : "",
    },
    {
      enabled: true,
    }
  );

  const steps = useMemo(() => {
    const hasLocations =
      data && data.brand && data.brand.numLocations > 0 ? true : false;
    const hasListings =
      data && data.brand && data.brand.numListings > 0 ? true : false;
    const hasSubscription = data && data.brand && data.brand.stripeCustomerId;
    const stepsCompleted = [hasLocations, hasListings, hasSubscription].filter(
      Boolean
    ).length;
    const nextStep = !hasLocations
      ? "Add your locations"
      : !hasListings
      ? "Create your first listing"
      : "Go live";

    return {
      hasLocations,
      hasListings,
      hasSubscription,
      stepsCompleted,
      nextStep,
    };
  }, [data]);

  if (!data || !data.brand) {
    return null;
  }

  const stepsToGo = 4 - (steps.stepsCompleted + 1);

  if (
    window.width !== undefined &&
    window.width < MOBILE_BREAKPOINT &&
    stepsToGo > 0
  ) {
    return (
      <>
        <SetUpGuideModal open={showUpgrade} setOpen={setShowUpgrade} />
        <Button
          size="xs"
          onClick={() => setShowUpgrade(!showUpgrade)}
          buttonType="tertiary"
        >
          {stepsToGo === 1 ? "1 step to go" : `${stepsToGo} steps to go`}
        </Button>
      </>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <SetUpGuideModal open={showUpgrade} setOpen={setShowUpgrade} />
      </ThemeProvider>
      <TrialWrap>
        <CurrentPlanLinkCard
          onClick={() => setShowUpgrade(!showUpgrade)}
          className="setup-guide-link-card"
        >
          <Text
            weight="bold"
            colorPreset="success"
            margin="0"
            size="s"
            isCompact
            className="setup-guide-progress-text"
          >
            {stepsToGo === 1 ? "1 step to go" : `${stepsToGo} steps to go`}
          </Text>
          <div className="setup-guide-wrap">
            <Flex
              margin="0 0 xs 0"
              direction="row"
              align="center"
              justify="space-between"
            >
              <Flex align="center" direction="row">
                <Text weight="semi" size="xs" margin="0">
                  Next up: {steps.nextStep}
                </Text>
                {steps.nextStep === "Go live" ? (
                  <div style={{ marginBottom: -3, marginLeft: 4 }}>
                    <img alt="rocket icon" width={14} src={RocketEmoji} />
                  </div>
                ) : null}
              </Flex>
            </Flex>
          </div>
          <div className="setup-guide-wrap">
            <Button style={{ borderRadius: 5 }} size="xs">
              View steps
            </Button>
          </div>
        </CurrentPlanLinkCard>
      </TrialWrap>
    </>
  );
};

const SettingsDropdown = (props: SettingsProps) => {
  const client = useGqlClient();
  const createStripePortal = useCreateStripePortalMutation(client);

  return (
    <div>
      <Dropdown
        margin="0 0 xs 0"
        onClick={() => props.settingsToggle(!props.showSettings)}
      >
        <IconWrap>
          <SettingsIcon colorPreset="text" className="stroke-hover" />
        </IconWrap>
        <span className="nav-link-label">Settings</span>
        <Chevron
          size="s"
          style={{ marginLeft: "auto" }}
          direction={props.showSettings ? "up" : "down"}
          animateTransition
          className="nav-chevron"
        />
      </Dropdown>
      <DropdownLinks isOpen={props.showSettings}>
        <Link to="/b/profile">
          <IconWrap nestedLink>
            <ProfileIcon colorPreset="text" className="stroke-hover" />
          </IconWrap>
          <span className="nav-link-label">Profile</span>
        </Link>
        <Link to="/b/settings">
          <IconWrap nestedLink>
            <IntegrationsIcon colorPreset="text" className="stroke-hover" />
          </IconWrap>
          <span className="nav-link-label">Integrations</span>
        </Link>
        {props.showUpgradePrompt ? (
          <FakeLink onClick={() => props.setShowUpgradeOverlay(true)}>
            <IconWrap nestedLink>
              <TeamIcon colorPreset="text" className="stroke-hover" />
            </IconWrap>
            <span className="nav-link-label">Team</span>
            <div style={{ marginLeft: "auto" }} className="nav-upgrade-prompt">
              <UpgradePrompt feature="team" type="icon" />
            </div>
          </FakeLink>
        ) : (
          <Link to="/b/team">
            <IconWrap nestedLink>
              <TeamIcon colorPreset="text" className="stroke-hover" />
            </IconWrap>
            <span className="nav-link-label">Team</span>
          </Link>
        )}
        {props.stripeCustomerId ? (
          <FakeLink
            onClick={() =>
              createStripePortal.mutate(
                {},
                {
                  onSuccess: (res: any) => {
                    window.open(res.createStripePortal.redirectUrl, "_blank");
                  },
                }
              )
            }
          >
            <IconWrap nestedLink>
              <BillingIcon colorPreset="text" className="stroke-hover" />
            </IconWrap>
            <span className="nav-link-label">Billing</span>
          </FakeLink>
        ) : (
          <Link to="/b/billing">
            <IconWrap nestedLink>
              <BillingIcon colorPreset="text" className="stroke-hover" />
            </IconWrap>
            <span className="nav-link-label">Billing</span>
          </Link>
        )}
      </DropdownLinks>
    </div>
  );
};

const DropdownLinks = styled.div<{ isOpen: boolean }>`
  transition: opacity 50ms ease-in-out, height 50ms ease-in-out;
  ${(p) =>
    p.isOpen
      ? css`
          visibility: visible;
          opacity: 1;
          height: auto;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          height: 0;
        `}
`;

interface Props {
  steps: Steps;
  expired: boolean;
}

const ProgressBarFg = styled.div<{ expired: boolean; progressWidth: number }>`
  width: ${({ progressWidth }) => `${progressWidth}%`};
  height: 100%;
  background-color: ${(p) =>
    p.expired ? p.theme.color.warning : p.theme.color.primary};
  transition: width 0.3s ease;
  border-radius: ${(p) => p.theme.misc.borderRadius};
`;

const ProgressBarBg = styled.div<{ expired: boolean }>`
  background-color: ${(p) =>
    p.expired ? p.theme.color.warning : p.theme.color.secondary}55;
  border-radius: ${(p) => p.theme.misc.borderRadius};
  width: 100%;
  height: 100%;
`;

const ProgressBarWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 8px;
`;

export const ProgressBar: React.FC<Props> = ({ steps, expired }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      if (wrapperRef.current) {
        setProgressWidth((steps.currentStep / steps.totalSteps) * 100);
      }
    };

    // Initial width calculation
    updateWidth();

    // Resize observer to handle window resizing
    const handleResize = () => updateWidth();

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [steps]);

  return (
    <ProgressBarWrap ref={wrapperRef}>
      <ProgressBarBg expired={expired}>
        <ProgressBarFg expired={expired} progressWidth={progressWidth} />
      </ProgressBarBg>
    </ProgressBarWrap>
  );
};
